import * as React from 'react'
import TimeoutModal from '../../components/timeoutModal'
import { useAuth } from '../../hooks/useAuth'
import Layout from '../../layout'
import LinkRFIDPage from '../../pagesComponents/login/link-rfid'

const LinkRFID = () => {
	const { resetInVenueOnboarding } = useAuth()

	return (
		<Layout theme="light" title="Account Created">
			<LinkRFIDPage
				basePath="/in-venue-onboarding"
				inVenue
				onClose={() => {
					resetInVenueOnboarding()
				}}
			/>
			<TimeoutModal onReset={resetInVenueOnboarding} />
		</Layout>
	)
}

export default LinkRFID
