import * as React from 'react'
import BasePage from './basePage'
import DuckNote from './duckNote'
import { StaticImage } from 'gatsby-plugin-image'
import { Flex, Text, Heading } from '@chakra-ui/react'

const DuckPage = (props) => {
	const {
		access,
		parent,
		theme,
		backBtn,
		title,
		subtitle,
		topText,
		topLargeText,
		primaryBtn,
		secondaryBtn,
		children,
		goldBanner,
		rightBtn,
		displayHeader
	} = props

	return (
		<BasePage
			access={access}
			parent={parent}
			theme={theme}
			backBtn={backBtn}
			primaryBtn={primaryBtn}
			secondaryBtn={secondaryBtn}
			rightBtn={rightBtn}
			noHeader={!displayHeader}
			flex={true}
			{...props}
		>
			<DuckNote
				text={topText}
				largeText={topLargeText}
				goldBanner={goldBanner}
				theme={theme}
			/>
			<Flex
				flex="1"
				alignItems="center"
				justifyContent="center"
				position="absolute"
				top="-40px"
				right="0"
				left="0"
				zIndex="1"
			>
				<StaticImage
					style={{
						width: 195,
						height: 136,
						marginRight: -170
					}}
					alt="Duck Top"
					src="../images/duck.png"
				/>
			</Flex>
			{!!title && (
				<Flex justifyContent="center" mt="10px">
					<Heading
						as="h1"
						color={theme === 'dark' ? 'white' : 'black'}
					>
						{title}
					</Heading>
				</Flex>
			)}
			{!!subtitle && (
				<Text variant="m" mt="16px" textAlign="center">
					{subtitle}
				</Text>
			)}
			{children}
		</BasePage>
	)
}

export default DuckPage
