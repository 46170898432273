import { get, post } from './client'

const validateShortCode = async ({ queryKey }) => {
	return await get(`rfid/validate-shortcode/${queryKey[1]}`)
}

const updateRFID = async (body) => {
	return await post('rfid/update-rfid', {
		body
	})
}

export { validateShortCode, updateRFID }
