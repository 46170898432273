import * as React from 'react'
import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const DuckNote = ({ text, largeText, goldBanner, theme }) => {
	return (
		<Flex direction="column" alignItems="center">
			<Container variant="note" mt="-20px" mx="0" pb="20px" pt="10px">
				<Box position="absolute" top="0" bottom="0" right="0" left="0">
					{goldBanner ? (
						<StaticImage
							style={{
								position: 'absolute',
								top: 0,
								bottom: 0,
								left: 0,
								right: 0
							}}
							alt="Note Background"
							src={'../images/banner-top-gold.png'}
						/>
					) : (
						<StaticImage
							style={{
								position: 'absolute',
								top: 0,
								bottom: 0,
								left: 0,
								right: 0
							}}
							alt="Note Background"
							src={'../images/banner-top.png'}
						/>
					)}
				</Box>
				<Text
					variant="note"
					position="relative"
					zIndex="1"
					color={theme === 'dark' ? 'white' : 'black'}
				>
					{text}
				</Text>
				{largeText && (
					<Text
						mt="10px"
						variant="note"
						w="100%"
						color={theme === 'dark' ? 'white' : 'black'}
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						position="relative"
						fontSize={32}
						lineHeight={1}
					>
						{largeText}
					</Text>
				)}
			</Container>
		</Flex>
	)
}

export default DuckNote
